#Header {
  z-index: 2000;
  position: absolute;
  left: 0;
  height: 170px;
  top: 0;
  width: 100%;
  background: #f4f9ff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0px 20px;

  a {
    text-decoration: none;
    color: rgb(250, 128, 63);
    display: flex;
    align-items: center;
  }

  .transportation {
    & > div {
      background: #ffffff;
      box-shadow: 0px 4px 56px rgba(0, 0, 0, 0.03),
        0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      padding: 10px 15px;
      font-size: 14px;
      width: 290px;
      display: flex;
      align-items: center;
    }
    img {
      margin-right: 16px;
    }
    span {
      font-size: 12px;
    }
    .count {
      padding-left: 10px;
      border-left: 1px solid #f2f2f2;
      height: 100%;
      color: var(--theme-dark);
      border-radius: 5px;
      font-size: 18px;
      min-width: 30px;
      min-height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  // TOP HEADER

  .top-header {
    height: 100px;
    width: 100%;
    justify-content: flex-end;
    column-gap: 20px;
    align-items: center;
    h2 {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 14px;
      color: var(--theme-color);
    }
    button {
      width: 55px;
      height: 55px;
      background: #4365db;
      border: 1px solid #ffffff;
      border-radius: 50% !important;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 3px;
    }
  }
}
