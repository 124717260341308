#Table {
  margin-top: 20px;
  border: 2px solid #ffffff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  background: #fff;
  padding: 20px 25px;

  &::-webkit-scrollbar {
    height: 20px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #e0e0e0;
    border-radius: 13px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: 8px solid #e0e0e0;
    border-radius: 13px;
    background: #bdbdbd;
  }

  h4 {
    font-size: 16px;
  }

  .form-check-input {
    border-radius: 3px !important;
    width: 14px;
    height: 14px;
    margin: 0;
    padding: 0;
  }

  table {
    border-radius: 3px;
    overflow: hidden;
    thead {
      background: #f4f9ff;
      color: var(--theme-color);
      border-radius: 3px;
    }
    width: 100%;

    tr {
      vertical-align: middle;
      text-align: center;
    }

    thead {
      th {
        padding: 10px 30px;
        font-size: 14px;
        text-align: center;
        font-weight: 600;
      }
    }

    tbody {
      th {
        padding: 19px 0;
      }
      td {
        white-space: nowrap;
        font-size: 14px;
      }
    }
  }
}
