#Register {
    width: 100%;
    height: 100vh;

    .auth-box {
        width: 100%;
        max-width: 370px;

        input,
        select {
            background-color: #F4F9FF;
            color: var(--theme-color);
            height: 50px;
            border: 1px solid #D0DBFF;
            border-radius: 6px;
        }

        select {
            max-width: 110px;
            width: 100%;
            margin-right: 10px;
            background-image: url('../../assets/icon/arrow-circle-down.svg');
            background-size: 22px;
        }

        button {
            border-radius: 6px;
            height: 50px;
        }

        .form-group {
            position: relative;
            display: flex;
            align-items: center;

            .icon {
                cursor: pointer;
                right: 15px;
                position: absolute;
            }
        }
    }
}