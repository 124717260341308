#Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  background: rgba(0, 0, 0, 0.308);
  opacity: 0;
  transition: 0.3s ease;
  width: 100%;
  height: 100vh;
  z-index: 100000000000000;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    visibility: visible;
    opacity: 1;
  }

  .modal-header {
    background: var(--theme-color);
    height: 50px;
    width: 100%;
    position: absolute;
    top: 0;
  }

  .modal-box {
    overflow: hidden;
    width: 380px;
    height: 500px;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.116);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .close {
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 13px;

      i {
        color: rgb(74, 74, 74);
        font-size: 18px;
      }
    }

    .field-wrapper {
      padding-right: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 390px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 17px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        border: 4px solid #fff;
        border-radius: 2px;
        background: var(--theme-color);
      }

      select {
        width: 100%;
        background-color: white;
        border: 1px solid rgb(226, 226, 226);
        color: var(--theme-dark);
        font-size: 14px;
        height: 35px !important;
        min-height: 35px;
        padding-left: 10px;
        padding-right: 5px;
        border-radius: 4px;
      }

      input {
        width: 100%;
        background: white;
        border: 1px solid rgb(226, 226, 226);
        color: var(--theme-dark);
        font-size: 14px;
        height: 35px !important;
        min-height: 35px;
        padding-left: 10px;
        padding-right: 5px;
        margin: 5px 0;
        border-radius: 4px;
        transition: 0.3s ease;

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #6376ba !important;
        }
      }
    }

    .buttons {
      border-top: 1px solid #eee;
      position: absolute;
      align-items: center;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 50px;
      padding: 0 10px;

      button {
        color: white;
        border: none;
        width: 50%;
        font-size: 14px;
        padding: 4px 0;
        border-radius: 2px;

        &:first-child {
          color: #d51616;
          background: white;
          border: 1px solid var(--theme-color);
          font-weight: 500;
          border: 1px solid #d5161637;
          margin-right: 5px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #Modal {
    height: 100%;

    .modal-box {
      height: 100%;
      width: 100%;

      .field-wrapper {
        height: calc(100% - 100px);
      }
    }

    .buttons {
      position: fixed;
    }
  }
}
