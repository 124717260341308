#Home {
  position: relative;
  width: 100%;
  height: calc(100vh - 170px);
  background: #f4f9ff;
  padding: 10px;
}
#Home > .map {
  display: flex;
  flex-direction: row;
  justify-content: center;
}