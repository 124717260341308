#TruckInformation {
  position: relative;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 1000000;
  background: #fff;
  border: 2px solid #ffffff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: 0px;
  height: calc(100vh - 193px);
  overflow-y: scroll;
  overflow-x: hidden;
  visibility: hidden;
  opacity: 0;
  transition: width 0.3s ease;
  /* Track */
  /* Handle */
}
#TruckInformation.active {
  margin: 0 0 0px 10px;
  padding: 20px 16px;
  width: 400px;
  visibility: visible;
  opacity: 1;
}
#TruckInformation::-webkit-scrollbar {
  width: 17px;
}
#TruckInformation::-webkit-scrollbar-track {
  background: transparent;
}
#TruckInformation::-webkit-scrollbar-thumb {
  border: 4px solid #fff;
  border-radius: 6px;
  background: rgb(6, 46, 91);
}
#TruckInformation .close {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#TruckInformation .close i {
  font-size: 20px;
}
#TruckInformation .truck {
  padding-top: 30px;
}
#TruckInformation > ul {
  padding: 0;
  width: 500px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
#TruckInformation > ul > li {
  display: flex;
  justify-content: space-between;
  border-bottom: 1.5px solid #f2f2f2;
  padding: 15px 2px;
  white-space: nowrap;
}
#TruckInformation > ul > li span {
  white-space: nowrap;
  font-size: 14px;
}
#TruckInformation > ul h3 {
  text-align: center;
  font-size: 14px;
  background-color: var(--theme-color);
  color: #fff;
  padding: 23px 50px;
  border-radius: 3px;
  white-space: nowrap;
}
#TruckInformation .status {
  border-radius: 3px;
  padding: 5px 10px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
}
#TruckInformation .status.parking {
  background: #ffe7e7;
  color: #f73838;
}
#TruckInformation .status.in-road {
  background: #e9ffe7;
  color: #1cd127;
}
#TruckInformation .status.warning {
  background: #fffbe7;
  color: #f78b38;
}