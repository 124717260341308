@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  list-style: none;
}

body::-webkit-scrollbar {
  width: 17px;
}
body::-webkit-scrollbar-track {
  background: transparent;
}
body::-webkit-scrollbar-thumb {
  border: 4px solid #fff;
  border-radius: 6px;
  background: rgb(6, 46, 91);
}

:root {
  --theme-color: #4365db;
  --theme-dark: #4f4f4f;
}

.App {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  min-height: 100vh;
}

main {
  position: relative;
  width: 100%;
}

.bg-current {
  background-color: var(--theme-color);
}

.text-current {
  color: var(--theme-color) !important;
}

.text-dark {
  color: var(--theme-dark) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

textarea:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=datetime]:focus,
input[type=datetime-local]:focus,
input[type=date]:focus,
input[type=month]:focus,
input[type=time]:focus,
input[type=week]:focus,
input[type=number]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=color]:focus,
input[type=checkbox]:focus,
select:focus,
.uneditable-input:focus {
  box-shadow: none;
  outline: 0 none;
}

input::placeholder {
  color: var(--theme-color) !important;
}

* button {
  cursor: pointer;
  height: 36px;
  border-radius: 4px !important;
  color: white;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.116);
  transition: 0.2s ease;
  font-size: 16px;
  border: 1px solid transparent;
  transform: scale(1);
  transition: 0.3s ease;
}

button:disabled {
  transform: scale(1) !important;
  cursor: auto;
  background: rgb(166, 166, 166) !important;
}

button:active {
  transform: scale(0.97);
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}