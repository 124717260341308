#YandexMap {
  overflow: hidden;
  border: 2px solid #ffffff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  height: calc(100vh - 193px);
  .map {
    height: calc(100vh - 193px);
  }
}
