#Login {
  width: 100%;
  height: 100vh;
}
#Login .auth-box {
  width: 100%;
  max-width: 370px;
}
#Login .auth-box input {
  background: #F4F9FF;
  height: 50px;
  border: 1px solid #D0DBFF;
  border-radius: 6px;
  color: var(--theme-color);
}
#Login .auth-box button {
  border-radius: 6px;
  height: 50px;
}
#Login .auth-box .form-group {
  position: relative;
  display: flex;
  align-items: center;
}
#Login .auth-box .form-group .icon {
  cursor: pointer;
  right: 15px;
  position: absolute;
}