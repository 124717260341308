#Login {
    width: 100%;
    height: 100vh;


    .auth-box {
        width: 100%;
        max-width: 370px;

        input {
            background: #F4F9FF;
            height: 50px;
            border: 1px solid #D0DBFF;
            border-radius: 6px;
            color: var(--theme-color);
        }

        button {
            border-radius: 6px;
            height: 50px;
        }

        .form-group {
            position: relative;
            display: flex;
            align-items: center;

            .icon {
                cursor: pointer;
                right: 15px;
                position: absolute;
            }
        }
    }
}