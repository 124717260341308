#Navbar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--theme-color);
  height: 100%;
  width: 225px;
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;

  * {
    color: white;
  }

  &::-webkit-scrollbar {
    width: 17px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: 4px solid var(--theme-color);
    border-radius: 2px;
    background: rgb(6, 46, 91);
  }

  .logo {
    margin-top: 50px;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
  }
  .pages {
    margin-bottom: 0;
    padding: 45px 15px;
    width: 100%;
    border-bottom: 1px solid #3355cc;
    li {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
      width: 100%;
      a {
        display: flex;
        align-items: flex-end;
        column-gap: 16px;
        text-decoration: none;
        span {
          font-size: 14px;
        }
      }
    }
  }

  button {
    background: none !important;
    box-shadow: none;
    margin: 0px auto;
    margin: 30px 0;
  }
}
