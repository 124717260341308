#Filter {
  height: calc(100vh - 193px);
  width: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: fixed;
  right: 0;
  z-index: 999;
  transition: width 0.3s ease;
  z-index: 10000;
  h5{
    font-size: 16px;
    color: var(--theme-color);
  }
  .content {
    padding: 5px 10px 30px 10px;
    width: 100%;
    display: none;
    height: calc(100vh - 170px);
    label,
    select {
      font-size: 14px !important;
      color: var(--theme-dark);
    }
    select {
      border-radius: 4px;
      height: 42px;
      border: 1.5px solid #2a50da2d;
    }
    small {
      font-size: 12px;
    }

    &::-webkit-scrollbar {
      width: 17px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border: 4px solid #fff;
      border-radius: 2px;
      background: rgb(6, 46, 91);
    }
  }

  &.active {
    width: 420px;

    .content {
      display: block;
      overflow-y: auto !important;
    }
  }

  button {
    width: 80%;
    height: 42px;
    font-size: 14px;
    margin-top: 50px;
  }

  .clear-filter {
    margin-top: 10px;
    background: rgb(51, 51, 51);
  }
}

@media (max-width: 500px) {
  #Filter {
    &.active {
      width: 80% !important;
    }

    button {
      font-size: 12px;
      height: 32px;
    }
  }
}
