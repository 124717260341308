#Filter {
  height: calc(100vh - 193px);
  width: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: fixed;
  right: 0;
  z-index: 999;
  transition: width 0.3s ease;
  z-index: 10000;
}
#Filter h5 {
  font-size: 16px;
  color: var(--theme-color);
}
#Filter .content {
  padding: 5px 10px 30px 10px;
  width: 100%;
  display: none;
  height: calc(100vh - 170px);
  /* Track */
  /* Handle */
}
#Filter .content label,
#Filter .content select {
  font-size: 14px !important;
  color: var(--theme-dark);
}
#Filter .content select {
  border-radius: 4px;
  height: 42px;
  border: 1.5px solid rgba(42, 80, 218, 0.1764705882);
}
#Filter .content small {
  font-size: 12px;
}
#Filter .content::-webkit-scrollbar {
  width: 17px;
}
#Filter .content::-webkit-scrollbar-track {
  background: transparent;
}
#Filter .content::-webkit-scrollbar-thumb {
  border: 4px solid #fff;
  border-radius: 2px;
  background: rgb(6, 46, 91);
}
#Filter.active {
  width: 420px;
}
#Filter.active .content {
  display: block;
  overflow-y: auto !important;
}
#Filter button {
  width: 80%;
  height: 42px;
  font-size: 14px;
  margin-top: 50px;
}
#Filter .clear-filter {
  margin-top: 10px;
  background: rgb(51, 51, 51);
}

@media (max-width: 500px) {
  #Filter.active {
    width: 80% !important;
  }
  #Filter button {
    font-size: 12px;
    height: 32px;
  }
}