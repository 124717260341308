#Table {
  margin-top: 20px;
  border: 2px solid #ffffff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  background: #fff;
  padding: 20px 25px;
  /* Track */
  /* Handle */
}
#Table::-webkit-scrollbar {
  height: 20px;
}
#Table::-webkit-scrollbar-track {
  background: #e0e0e0;
  border-radius: 13px;
}
#Table::-webkit-scrollbar-thumb {
  border: 8px solid #e0e0e0;
  border-radius: 13px;
  background: #bdbdbd;
}
#Table h4 {
  font-size: 16px;
}
#Table .form-check-input {
  border-radius: 3px !important;
  width: 14px;
  height: 14px;
  margin: 0;
  padding: 0;
}
#Table table {
  border-radius: 3px;
  overflow: hidden;
  width: 100%;
}
#Table table thead {
  background: #f4f9ff;
  color: var(--theme-color);
  border-radius: 3px;
}
#Table table tr {
  vertical-align: middle;
  text-align: center;
}
#Table table thead th {
  padding: 10px 30px;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
}
#Table table tbody th {
  padding: 19px 0;
}
#Table table tbody td {
  white-space: nowrap;
  font-size: 14px;
}