#Navbar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--theme-color);
  height: 100%;
  width: 225px;
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  /* Track */
  /* Handle */
}
#Navbar * {
  color: white;
}
#Navbar::-webkit-scrollbar {
  width: 17px;
}
#Navbar::-webkit-scrollbar-track {
  background: transparent;
}
#Navbar::-webkit-scrollbar-thumb {
  border: 4px solid var(--theme-color);
  border-radius: 2px;
  background: rgb(6, 46, 91);
}
#Navbar .logo {
  margin-top: 50px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
}
#Navbar .pages {
  margin-bottom: 0;
  padding: 45px 15px;
  width: 100%;
  border-bottom: 1px solid #3355cc;
}
#Navbar .pages li {
  width: 100%;
}
#Navbar .pages li:not(:last-child) {
  margin-bottom: 40px;
}
#Navbar .pages li a {
  display: flex;
  align-items: flex-end;
  column-gap: 16px;
  text-decoration: none;
}
#Navbar .pages li a span {
  font-size: 14px;
}
#Navbar button {
  background: none !important;
  box-shadow: none;
  margin: 0px auto;
  margin: 30px 0;
}