#Dashboard {
  background: #f4f9ff;
  min-height: calc(100vh - 170px);
  padding-bottom: 20px;
}
#Dashboard .main .segment-button {
  background: #eee;
  display: flex;
  align-items: center;
  padding: 0 2px;
  width: auto;
  border-radius: 6px;
  overflow: visible;
  height: 40px;
}
#Dashboard .main .segment-button a {
  white-space: nowrap;
  width: 100%;
  height: 90%;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 6px;
}
#Dashboard .main .segment-button a:not(.active) {
  box-shadow: none;
  color: black;
}
#Dashboard .main .segment-button a.active {
  background: rgb(23, 181, 23);
}