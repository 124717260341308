#TruckInformation {
  position: relative;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 1000000;
  background: #fff;
  border: 2px solid #ffffff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: 0px;
  height: calc(100vh - 193px);
  overflow-y: scroll;
  overflow-x: hidden;
  visibility: hidden;
  opacity: 0;
  transition: width 0.3s ease;

  &.active {
    margin: 0 0 0px 10px;
    padding: 20px 16px;
    width: 400px;
    visibility: visible;
    opacity: 1;
  }

  &::-webkit-scrollbar {
    width: 17px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: 4px solid #fff;
    border-radius: 6px;
    background: rgb(6, 46, 91);
  }

  .close {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 20px;
    }
  }

  .truck {
    padding-top: 30px;
  }

  & > ul {
    padding: 0;
    width: 500px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    & > li {
      display: flex;
      justify-content: space-between;
      border-bottom: 1.5px solid #f2f2f2;
      padding: 15px 2px;
      white-space: nowrap;
      span {
        white-space: nowrap;
        font-size: 14px;
      }
    }

    h3 {
      text-align: center;
      font-size: 14px;
      background-color: var(--theme-color);
      color: #fff;
      padding: 23px 50px;
      border-radius: 3px;
      white-space: nowrap;
    }
  }
  .status {
    border-radius: 3px;
    padding: 5px 10px;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;
    &.parking {
      background: #ffe7e7;
      color: #f73838;
    }
    &.in-road {
      background: #e9ffe7;
      color: #1cd127;
    }
    &.warning {
      background: #fffbe7;
      color: #f78b38;
    }
  }
}
